import React, { FC, useState } from 'react'
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  Button,
  DialogTitle,
  FormGroup,
  FormControlLabel
} from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import { Feedback, getErrorFeedback, getSuccessFeedback } from 'utils/feedback'
import {
  Person,
  EnableCognitoUserMutation,
  EnableCognitoUserMutationVariables,
  DisableCognitoUserMutation,
  DisableCognitoUserMutationVariables,
  CreateCognitoUserMutation,
  CreateCognitoUserMutationVariables,
  DeleteCognitoUserMutation,
  DeleteCognitoUserMutationVariables,
  GetCognitoUsersQuery,
  GetCognitoUsersQueryVariables,
  SendEmailInviteAppMutation,
  SendEmailInviteAppMutationVariables,
  EnableCognitoUserMfaMutation,
  DisableCognitotoUserMfaMutation,
  EnableCognitoUserMfaMutationVariables,
  DisableCognitotoUserMfaMutationVariables,
  CongitoPerson
} from 'generated/graphql'
import { useMutation } from '@apollo/react-hooks'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { ResetPasswordModal } from '../ResetPassword'
import { CheckIcon, ClearIcon, CloseIcon } from 'components/common/icons'
import { SwitchButton, SwitchData } from 'components/SwitchButton'
import {
  CREATE_COGNITO_USER,
  DELETE_COGNITO_USER,
  DISABLE_COGNITO_USER,
  ENABLE_COGNITO_USER,
  GET_COGNITO_USERS,
  SEND_EMAIL_INVITE,
  DISABLE_COGNITO_USER_MFA,
  ENABLE_COGNITO_USER_MFA
} from 'graphql/common'
import { LoadingSkeleton } from 'components/common/LoadingSkeleton'

interface InternalUserStateProps {
  inCognitoPool?: CongitoPerson | null
  isActiveInCognito: boolean
  openChangeStatusConfirm: boolean
  openChangeMFAStatusConfirm: boolean
  openDeleteUserConfirm: boolean
  openResetPassword: boolean
  openCreateUser: boolean
  isRequestSendInvite: boolean
}

interface UserModalProps {
  onCloseModal: () => void
  selectedItem: Person
  onDone: () => void
  setAutoHideFeedback: (value: Feedback) => void
}

const STATUS: SwitchData[] = [
  {
    text: 'Active MFA',
    value: true,
    activeColor: 'Green',
    icon: <CheckIcon />
  },
  {
    text: 'Inactive MFA',
    value: false,
    activeColor: 'red',
    icon: <ClearIcon />
  }
]

const useStyles = makeStyles(() => ({
  statusText: {
    fontSize: 17,
    fontWeight: 'bold'
  },
  toggleActiveStatus: {
    fontSize: 17,
    display: 'flex',
    margin: 5
  }
}))

export const UserModal: FC<UserModalProps> = ({ onCloseModal, selectedItem, onDone, setAutoHideFeedback }) => {
  const classes = useStyles()

  const [state, setState] = useState<InternalUserStateProps>({
    inCognitoPool: null,
    isActiveInCognito: false,
    openChangeStatusConfirm: false,
    openChangeMFAStatusConfirm: false,
    openDeleteUserConfirm: false,
    openResetPassword: false,
    openCreateUser: false,
    isRequestSendInvite: false
  })

  const {
    inCognitoPool,
    isActiveInCognito,
    openChangeStatusConfirm,
    openChangeMFAStatusConfirm,
    openDeleteUserConfirm,
    openResetPassword,
    openCreateUser,
    isRequestSendInvite
  } = state

  const [enableCognitoUser] = useMutation<EnableCognitoUserMutation, EnableCognitoUserMutationVariables>(
    ENABLE_COGNITO_USER
  )
  const [disableCognitoUser] = useMutation<DisableCognitoUserMutation, DisableCognitoUserMutationVariables>(
    DISABLE_COGNITO_USER
  )
  const [createCognitoUser] = useMutation<CreateCognitoUserMutation, CreateCognitoUserMutationVariables>(
    CREATE_COGNITO_USER
  )
  const [deleteCognitoUser] = useMutation<DeleteCognitoUserMutation, DeleteCognitoUserMutationVariables>(
    DELETE_COGNITO_USER
  )
  const [sendEmailInviteApp] = useMutation<SendEmailInviteAppMutation, SendEmailInviteAppMutationVariables>(
    SEND_EMAIL_INVITE
  )

  const [enaleCognitoUserMFA] = useMutation<EnableCognitoUserMfaMutation, EnableCognitoUserMfaMutationVariables>(
    ENABLE_COGNITO_USER_MFA
  )

  const [disableCognitoUserMFA] = useMutation<
    DisableCognitotoUserMfaMutation,
    DisableCognitotoUserMfaMutationVariables
  >(DISABLE_COGNITO_USER_MFA)

  // Get cognito user info
  const { loading: isLoadingCognitoUser } = useQuery<GetCognitoUsersQuery, GetCognitoUsersQueryVariables>(
    GET_COGNITO_USERS,
    {
      variables: {
        filter: {
          Emails: [selectedItem.Email || '']
        }
      },
      skip: !selectedItem.Email || !!selectedItem.internalPerson?.IsADFS,
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const inCognitoPool = data?.getCognitoUsers.find(
          item => item.Email.toLowerCase() === selectedItem?.Email?.toLowerCase()
        )
        setState({
          ...state,
          inCognitoPool: inCognitoPool,
          isActiveInCognito: !!inCognitoPool?.Enabled
        })
      }
    }
  )

  const onHandleChangeStatusConfirm = () => {
    if (isActiveInCognito) {
      disableCognitoUser({
        variables: {
          UserEmail: selectedItem?.Email || ''
        }
      })
        .then(({ data }) => {
          if (data?.disableCognitoUser) {
            setAutoHideFeedback(getSuccessFeedback(`Disable Cognito Account successfully`))
            onDone()
          }
        })
        .catch(error => {
          setAutoHideFeedback(getErrorFeedback(error.message))
        })
    } else {
      enableCognitoUser({
        variables: {
          UserEmail: selectedItem?.Email || ''
        }
      })
        .then(({ data }) => {
          if (data?.enableCognitoUser) {
            setAutoHideFeedback(getSuccessFeedback(`Enable Cognito Account successfully`))
            onDone()
          }
        })
        .catch(error => {
          setAutoHideFeedback(getErrorFeedback(error.message))
        })
    }
  }

  const onHandleChangeMFAStatus = async () => {
    try {
      if (!selectedItem.IsEnableMFA) {
        if (!selectedItem.IsActive) {
          return setAutoHideFeedback(getErrorFeedback('Cannot enable MFA Phone for inactive user'))
        }
        const { data } = await enaleCognitoUserMFA({
          variables: {
            UserEmail: selectedItem?.Email || ''
          }
        })
        if (data?.enableCognitoUserMFA) {
          setAutoHideFeedback(getSuccessFeedback(`MFA is enable successfully`))
          onDone()
        }
      }
      if (selectedItem.IsEnableMFA) {
        const { data } = await disableCognitoUserMFA({
          variables: {
            UserEmail: selectedItem?.Email || ''
          }
        })
        if (data?.disableCognitoUserMFA) {
          setAutoHideFeedback(getSuccessFeedback(`MFA is disabled successfully`))
          onDone()
        }
      }
    } catch (error) {
      setAutoHideFeedback(getErrorFeedback((error as Error)?.message))
    }
  }

  const onHandleDeleteUserConfirm = () => {
    deleteCognitoUser({
      variables: {
        UserEmail: selectedItem?.Email || ''
      }
    })
      .then(({ data }) => {
        if (data?.deleteCognitoUser) {
          setAutoHideFeedback(
            getSuccessFeedback(
              `${selectedItem?.Firstname} ${selectedItem?.Lastname} has been deleted from Cognito successfully`
            )
          )
          onDone()
        }
      })
      .catch(error => {
        setAutoHideFeedback(getErrorFeedback(error.message))
      })
  }

  const onHandleCreateUser = () => {
    if (!selectedItem?.Email) {
      setAutoHideFeedback(getErrorFeedback('User is missing email. Cannot complete account actions for such users'))
    } else {
      createCognitoUser({
        variables: {
          input: {
            UserName: selectedItem?.Email || '',
            Password: 'password'
          }
        }
      })
        .then(({ data }) => {
          if (data?.createCognitoUser) {
            setAutoHideFeedback(getSuccessFeedback(`A new user has been added to cognito successfully`))
            onDone()
          }
        })
        .catch(error => {
          setAutoHideFeedback(getErrorFeedback(error.message))
        })
    }
  }

  const checkUserType = () => {
    if (selectedItem?.internalPerson?.IsADFS) {
      return 'Single Sign On (Corporate Account)'
    } else {
      if (inCognitoPool && isActiveInCognito) {
        return 'Custom LinkedSite Account - Active'
      } else {
        return 'Custom LinkedSite Account - Inactive'
      }
    }
  }

  const sendEmailInvite = () => {
    setState({ ...state, isRequestSendInvite: true })
    sendEmailInviteApp({
      variables: {
        input: {
          AssignedTo: selectedItem?.ID
        }
      }
    })
      .then(({ data }) => {
        if (data?.sendEmailInviteApp) {
          setAutoHideFeedback(getSuccessFeedback(data.sendEmailInviteApp))
          setState({ ...state, isRequestSendInvite: false })
          onDone()
        }
      })
      .catch(error => {
        setState({ ...state, isRequestSendInvite: false })
        setAutoHideFeedback(getErrorFeedback(error.message))
      })
  }

  return (
    <>
      {isLoadingCognitoUser ? (
        <LoadingSkeleton />
      ) : (
        <Dialog onClose={onCloseModal} open={!!selectedItem} maxWidth={'md'} fullWidth>
          <DialogTitle>{`${selectedItem?.Firstname} ${selectedItem?.Lastname}`}</DialogTitle>
          <DialogContent style={{ padding: 30 }}>
            <Grid container spacing={3}>
              <Grid container item spacing={3}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      disabled
                      id="email"
                      label="Email"
                      name="Email"
                      value={selectedItem?.Email}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      disabled
                      id="company"
                      label="Company"
                      name="Company"
                      value={selectedItem?.internalPerson?.company.Name}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      disabled
                      id="mobile"
                      label="Mobile"
                      name="Mobile"
                      value={selectedItem?.Phone ? selectedItem?.Phone : 'No phone available'}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <SwitchButton
                          data={STATUS}
                          selected={`${selectedItem?.IsEnableMFA}`}
                          onChange={(e: string | number) => {
                            if (e == null) return
                            setState({ ...state, openChangeMFAStatusConfirm: true })
                          }}
                        />
                      }
                      label=""
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid item xs={12} container spacing={4}>
                <Grid item xs={6}>
                  <Typography className={classes.statusText}>Employee Status</Typography>
                  {selectedItem?.IsActive ? (
                    <Typography className={classes.toggleActiveStatus}>
                      <CheckIcon color="primary" /> Active
                    </Typography>
                  ) : (
                    <Typography className={classes.toggleActiveStatus}>
                      {<CloseIcon htmlColor="red" />} Inactive - Please contact HR to update the employee to an Active
                      status in GeoTrak and Workday
                    </Typography>
                  )}

                  <Typography className={classes.statusText}>Account Type</Typography>
                  <Typography className={classes.toggleActiveStatus}>{checkUserType()}</Typography>
                </Grid>

                <Grid item xs={6} className="flex flex-col gap-3">
                  {((!selectedItem?.internalPerson?.IsADFS && !inCognitoPool) ||
                    (inCognitoPool && !isActiveInCognito)) && (
                    <Button
                      disabled={!selectedItem?.IsActive}
                      className="w-fit"
                      color="secondary"
                      variant="contained"
                      onClick={() => setState({ ...state, openCreateUser: true })}
                    >
                      Create Custom LinkedSite Account
                    </Button>
                  )}

                  {inCognitoPool && isActiveInCognito && (
                    <>
                      <Button
                        className="w-fit"
                        color="secondary"
                        variant="contained"
                        onClick={() => setState({ ...state, openChangeStatusConfirm: true })}
                      >
                        Inactive Custom LinkedSite Account
                      </Button>

                      <Button
                        className="w-fit"
                        color="secondary"
                        variant="contained"
                        onClick={() => setState({ ...state, openResetPassword: true })}
                      >
                        Reset Password Custom LinkedSite Account
                      </Button>
                    </>
                  )}

                  <Button
                    className="w-fit"
                    disabled={
                      isRequestSendInvite ||
                      !(selectedItem.IsActive && (isActiveInCognito || selectedItem.internalPerson?.IsADFS))
                    }
                    variant="contained"
                    onClick={() => sendEmailInvite()}
                  >
                    Send LinkedSite Mobile Download Invite
                  </Button>
                </Grid>
              </Grid>

              <Grid container item justifyContent="flex-end">
                <Grid item>
                  <Button variant="contained" onClick={onCloseModal}>
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          {openChangeStatusConfirm && (
            <ConfirmDialog
              buttonText="Update"
              handleClose={() => setState({ ...state, openChangeStatusConfirm: false })}
              open={openChangeStatusConfirm}
              onConfirm={onHandleChangeStatusConfirm}
              title="Account status"
              description={`Are you sure to ${isActiveInCognito ? 'disable' : 'enable'} this user account ?`}
            />
          )}

          {openChangeMFAStatusConfirm && (
            <ConfirmDialog
              buttonText="Update"
              handleClose={() => setState({ ...state, openChangeMFAStatusConfirm: false })}
              open={openChangeMFAStatusConfirm}
              onConfirm={onHandleChangeMFAStatus}
              title="MFA status"
              description={`Are you sure to ${selectedItem.IsEnableMFA ? 'disable' : 'enable'} MFA this user account ?`}
            />
          )}

          {openDeleteUserConfirm && (
            <ConfirmDialog
              buttonText="Delete"
              handleClose={() => setState({ ...state, openDeleteUserConfirm: false })}
              open={openDeleteUserConfirm}
              onConfirm={onHandleDeleteUserConfirm}
              title="Delete account"
              description="Are you sure to delete this user account in AWS Cognito Identity Service? "
            />
          )}

          {openCreateUser && (
            <ConfirmDialog
              buttonText="Create"
              handleClose={() => setState({ ...state, openCreateUser: false })}
              open={openCreateUser}
              onConfirm={onHandleCreateUser}
              title="Create account"
              description="Are you sure to create this user account in AWS Cognito Identity Service ? User will receive an email for his/her temporary password and can get access to the system."
            />
          )}

          {openResetPassword && (
            <ResetPasswordModal
              isOpen={openResetPassword}
              onCloseResetPasswordModal={() => setState({ ...state, openResetPassword: false })}
              selectedItem={selectedItem}
              setAutoHideFeedback={setAutoHideFeedback}
            />
          )}
        </Dialog>
      )}
    </>
  )
}
